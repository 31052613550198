import React, {useState} from "react";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/b85f2040-175e-11ed-98e8-53e0970ab51c";

const ContactForm = () => {
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
          setSubmitted(true);
        }, 100);
      };

      if (submitted) {
        return (
          <>
            <h2>Thank you!</h2>
            <div>We'll be in touch soon.</div>
          </>
        );
      }

      return (

  


        <form
          action={FORM_ENDPOINT}
          onSubmit={handleSubmit}
          method="POST"
          target="_blank"
        >     
        
         <p>
         BMG DST 2022 closed in 2023 and is currently not accepting investors. We will be launching a new DST in 2024. Please contact us if you need assistance with a 1031 exchange. 
         </p>


          <div>
            <label>Name</label>
            <input type="text" placeholder="Your name" name="name" required />
          </div>

          <div>
            <label>Email</label>
            <input type="email" placeholder="Your Email" name="email" required />
          </div>

          <div>
            <label>Company or firm</label>
            <input type="text" placeholder="Your Company" name="company" />
          </div>

          <div>
            <label>Message</label>
            <textarea placeholder="Your message" name="message" required />
          </div>

          <div>
            <button type="submit"> Send a message </button>
          </div>
        </form>
      );
    };
    
    export default ContactForm;
    

    

