import React from "react";
import './Home.css';
import './About.css';




class
    About extends React.Component {
    render() {


        return (
            <div id="aboutMain">

                <div class="pageHeader">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h1>| ABOUT</h1>
                            </div>
                        </div>

                    </div>

                </div>

                <div id="overviewText" class="homeText">
                    <div class="container">

                        <div class="row" style={{
                            borderLeft: `2px solid var(--bmgBlueLowerOpacity)`,
                            marginBottom: `20px`
                        }}>
                            <div class="col-12">
                                <p>Rule 1031, also known as a 1031 Exchange, is a tax-deferred investment strategy. It works by allowing you as the investor to defer capital gains taxes when selling properties. It simply requires that you reinvest profits from the sale into new, like-kind properties, and meet other certain criteria on a schedule that identifies the properties. In theory, as long as gains from sales of assets are immediately reinvested in this manner, it’s possible to indefinitely defer payment of capital gains tax obligations.</p>
                                <p>In a 1031 exchange, upon selling your property, you will transfer the capital from the sale to a Qualified Intermediary (QI). A QI is a company that acts as an impartial custodian of funds in the process. Once you have identified a QI, you will have 45 days from the sale of the relinquished property to identify a new property (ies) on a list with the QI. You also have 180 days from the sale of the relinquished property to close on the replacement property. These time frames are non-negotiable.

                                </p>
                                <p>BMG will work with you/your QI to utilize the properties in BMG DST as your identified properties. Ultimately you will take a pro-rata share of ownership (Beneficial Interest) in the eight properties in the DST.

                                </p>
                            </div>
                        </div>

                        <h2 class="title">The key advantages of investing your 1031 exchange proceeds with BMG:</h2>

                        <div class="row timeline-row">
                            <div class="col-sm-4 timeline-block">
                                <h2>1.</h2>
                                <p>
                                    You decrease your risk by investing in a well-organized portfolio of investment grade properties vs. buying individual properties.
                                </p>
                            </div>
                            <div class="col-sm-4 timeline-block">
                                <h2>2.</h2>
                                <p>
                                    You receive consistently high yield without the hassle of managing properties (passive).
                                </p>
                            </div>
                            <div class="col-sm-4 timeline-block">
                                <h2>3.</h2>
                                <p>
                                    You eliminate the stress and risk of identifying individual properties to acquire, dealing with brokers and deal negotiations, property diligence, etc. It is very similar to investing in a real estate fund.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="keyAdvantages">

                </div>

            </div>
        )
    }
}

export default About;
