import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    HashRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import App from './App';
import Homepage from './Home';
import About from './About';
import Who from './Who';
import Faq from './Faq';
import Contact from './Contact';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <HashRouter>

        <Routes>
            <Route path="/" element={<App />}>
                <Route path="/" element={<Navigate to="/Home" />} />
                <Route path='Home' element={<Homepage />} />
                <Route path='About' element={<About />} />
                <Route path='Who' element={<Who />} />
                <Route path='Faq' element={<Faq />} />
                <Route path='Contact' element={<Contact />} />
            </Route>

        </Routes>
    </HashRouter>
);


