import React from "react";
import { NavLink } from "react-router-dom";

import "./Home.css";
import dgColorPic from "./images/dgcolor.jpeg"




class
  Homepage extends React.Component {
  render() {


    return (
      <div>
        <section id="intro">
          <div id='homeBanner'>
            <div class='container' >
              <div class="row">

                <div class="col-lg-8">
                  <h1>ONE-STOP SOLUTION FOR YOUR 1031 EXCHANGE NEEDS
                  </h1>
                  <div class='bannerText'>
                    <p>We are proud to announce that the BMG DST (Delaware Statutory Trust) became fully subscribed in October 2023. We will be launching a new DST in early 2024. Please contact us if you need assistance with a 1031 Exchange.
                    </p>
                  </div>

                </div>

                <div class="col-sm-4"
                  style={{
                    backgroundSize: `cover`,
                    backgroundRepeat: `no-repeat`,
                    backgroundImage: `url(${dgColorPic})`,
                    backgroundPosition: `center`
                  }}
                >
                </div>

              </div>
            </div>

          </div>

          <div class="container">
            <div class="row">



              <div class="col-lg-8 homeText mainSpacing">



              <p>
                        <span class="dstName">BMG DST</span> is a Delaware Statutory Trust, which is simply a separate legal entity created under the laws of Delaware to hold title to one or more income producing commercial properties. It is an appropriate vehicle for 1031 exchange investors.
                      </p>


                      <div id="assetInfo">
                        <p>
                        At BMG, we have been managing multiple real estate funds for over 10 years and have over 150 properties under management. In particular, we invest in stable, defensive (recession-resistant) properties with a long-term lease and a credit-grade tenant, primarily in the discount segment i.e. Dollar General.                       </p>
                        <ul>
                          <li>BMG DST currently holds title to eight such investment grade, single-tenant commercial properties.   </li>

                          <li>Investment return is targeted to be 5.6%* annual yield, paid in quarterly installments in year 1, increasing to targeted 6.0%* annual yield over the course of 5-years.
                          </li>

                          <li>Strong potential for capital gains on a 5- to 7-year horizon
                          </li>


                        </ul>
                      </div>

                      <p><span style={{
                        fontSize: `small`,
                        lineHeight: `10%`
                        
                      }} >
                      *BMG DST 2022 is currently not accepting investors. We will be launching a new DST in 2024. Please contact us if you need assistance with a 1031 exchange. 
                      </span>
                      </p>

{/* 

                      <p><span style={{
                        fontSize: `small`,
                        lineHeight: `10%`
                      }}>
                      *This summary of terms is neither an offer to sell nor the solicitation of an offer to buy any securities, which can be made only by the PPM. Targeted yield portrayed herein is not guaranteed.  This material must be reviewed in conjunction with the PPM to fully understand all of the implications and risks of the offering to which it relates. Prospective investors should carefully read the PPM, including the section designated “Risk Factors” therein, and review any additional information they desire prior to making an investment.
                      </span>
                      </p>
                    
                    */}


              </div>

              <div class="col-lg-4 homeText">
                <div id="sidebar">

                  <div className="side-box">
                    <div class="side-link">
                      <NavLink to="/about">
                      <p>Our DST Offering</p>
                        LEARN MORE
                        
                        </NavLink>
                    </div>
                  </div>

                    <hr/>

                  <div class="side-box">
                    <div class="side-link">
                      <NavLink to="/who">                    
                        <p>About Battle Monument</p>
                        WHO WE ARE</NavLink>
                    </div>
                  </div>

                  <hr/>


                  <div class="side-box">
                    <div class="side-link">
                      <NavLink to="/faq">
                      <p>More Questions?</p>
                        FAQ</NavLink>
                    </div>
                  </div>

                  <hr/>

                  <div class="side-box">
                    <div class="side-link">
                      <a href="https://battlemonument.com" target={`_blank`}>
                      <p>Battle Monument Homepage</p>
                        Click Here ↗ </a>
                        
                    </div>
                  </div>

                </div>
              </div>

            </div>



{/*
            <div class="row body homeText">

              <div class="col-sm-4 linkContainer">
                <div class="link-box">
                  <p>Our DST Exchange Offering</p>
                  <NavLink to="/about"><button class="link-button">LEARN MORE</button></NavLink>
                </div>
              </div>

              <div class="col-sm-4 linkContainer">
                <div class="link-box">
                  <p>About Battle Monument</p>
                  <NavLink to="/about"><button class="link-button">LEARN MORE</button></NavLink>
                </div>
              </div>

              <div class="col-sm-4 linkContainer">
                <div class="link-box">
                  <p>More Questions?</p>
                  <NavLink to="/faq"><button class="link-button">FAQ</button></NavLink>
                </div>
              </div>

            </div>
*/}


          </div>
        </section>

      </div>
    );
  }
}

export default Homepage;
