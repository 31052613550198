import React from "react";


import "./nav.css";
import MenuLink from './MenuLink';
import Pic from './images/logoJustText.png';
import Hamburger from './images/hamburger.png';







class Header extends React.Component {
  render() {




    const toggleMenu = () => {
      let menu = document.getElementById('collapsible');

      if (menu.style.display === 'none') {
        console.log('nice');
        menu.style.display = 'block';
      } else {
        menu.style.display = 'none';
      }


    }

    return (
      <div id="headerContainer">

        

        <div id="header">
          <div id="hamburgerDiv">
          <div style={{ 
            textAlign: `center`
         }}>
            <img
              src={Hamburger}
              alt='menu button'
              style={{ width: `40px` }}
              className='navBurger'
              onClick={toggleMenu}
            />
          </div>
          </div>


          <ul class="navUl">
            <li>
              <img id="headerLogo" src={Pic} alt="Battle Monument Logo" />
            </li>
            <li id="navTitle">
              BMG DST
            </li>
            <MenuLink dest="Home" />
            <MenuLink dest="About" />
            <MenuLink dest="Who" />
            <MenuLink dest="Faq" />
            <MenuLink dest="Contact" />
          </ul>



        </div>

        <div id="mobileUl">
          <ul id="collapsible">
          <MenuLink dest="Home" />
            <MenuLink dest="About" />
            <MenuLink dest="Who" />
            <MenuLink dest="Faq" />
            <MenuLink dest="Contact" />
          </ul>
          </div>


      </div>

    );
  }
}

export default Header;
