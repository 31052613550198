import React from "react";
import './nav.css';
import logo from './images/favicon.png';
import footerBanner from './images/desert_banner.jpeg'

class Footer extends React.Component {

  render() {
    return (
      <div class='footerContainer'>

      <div id="footerBackground" style={{
        backgroundAttachment: `fixed`,
        backgroundSize: `cover`,
        backgroundPosition: `center bottom`,
        backgroundRepeat: `no-repeat`,
        backgroundImage: `url(${footerBanner})`}}>
        <div id='footer'>
          <div class="container footerVisibility">
            <div class="row">

              <div class="col-md-4 contactInfo">
                <p>
                  Tel. 804-644-4924 <br />
                  530 East Main Street, <br />
                  Richmond, VA 23219 <br />
                  info@battlemonument.com
                </p>
              </div>

              <div class="col-md-2">
                <img id="footer-logo" src={logo} alt="Battle Monument Logo" />

              </div>


              <div class="col-md-6">
                <p id='disclaimer'> Battle Monument Group, 2022. All rights reserved.DISCLAIMER: This private organization is a non-federal entity and not an official activity of the United States Military Academy. It is not endorsed, recommended, or favored by the United States government. The views and opinions expressed by this organization do not necessarily state or reflect those of the United States Military Academy, Department of the Army, or Department of Defense.</p>

              </div>


            </div>
          </div>

        </div>

      </div>
      </div>
    )
  }
}

export default Footer;
