import React from "react";
import ContactForm from "./components/ContactForm";
import MonumentImg from "./images/monumentthumb.png";
import './Contact.css';





class
    Contact extends React.Component {
    render() {


        return (
            <div id="aboutMain" >

                <div class="pageHeader">
                    <div class="container">
                        <div class="row">

                            <div class="col-12">
                                <h1>| CONTACT US</h1>
                            </div>



                        </div>

                    </div>
                </div>


                <div class="container page-content form-page-content">

                    <div class="row">

                        <div class="col-lg-6">
                            <div class="form-div">
                                <ContactForm />
                            </div>
                        </div>

                        <div class="col-6"
                            style={{
                                backgroundSize: `cover`,
                                backgroundRepeat: `no-repeat`,
                                backgroundImage: `url(${MonumentImg})`,
                                backgroundPosition: `center-top`,
                            }}
                            >
                        </div>

                    </div>




                </div>


            </div>



        )
    }
}

export default Contact;
