import React from "react";
import John from './images/oliver2.jpg';
import Mike from './images/brumagin2.jpg';
import Peter from './images/stark2.jpg';
import ShellFade from './images/shellfade.png';

import './Home.css'




class
    Who extends React.Component {
    render() {


        return (
            <div id="aboutMain">

                <div class="pageHeader">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h1>| OUR COMPANY</h1>
                            </div>
                        </div>
                    </div>





                </div>

                <div class="container" id="aboutbox">
                    <div class="row">
                        <div class="col-lg-7 aboutText">
                            <p id="aboutmessage">Beginning in 2014, we set out to build fixed income funds that are tailored to the needs of Veterans and Industry Leading Professionals. We found that investing in stabilized, single-tenant properties, with investment-grade tenants,
                                leads to consistent dividend yield. The result is a product that is easily understood, producing consistent quarterly distributions that are above market, with low underlying risk and volatility which makes BMG Funds perfect for the fixed
                                income portion of a balanced portfolio.</p>
                        </div>
                        <div class="col-lg-5"
                                          style={{
                                            backgroundSize: `cover`,
                                            backgroundRepeat: `no-repeat`,
                                            backgroundImage: `url(${ShellFade})`,
                                            backgroundPosition: `top-left`
                                          }}
                        >
                        </div>
                    </div>

                </div>

                <br/>
                <br/>


                <div id="overviewText" class="aboutText">
                    <div class="container">


                        <div class="row ">
                            <div class="col-sm-5 portrait-container">
                                <img src={John} class="portrait img-fluid " alt="John Oliver"/>
                            </div>
                            <div class="col-sm-7 ">
                                <h6>W. John Oliver </h6>
                                <p>Managing and Founding Partner</p>
                                <p>John is a Co-Founder and Managing Partner at Battle Monument Group. He has 20 years of experience
                                    as an owner and executive in investor-funded companies, as CFO, VP Sales, and President. He
                                    served with the 82nd Airborne Division during Operation Desert Storm, and is a graduate of West
                                    Point (BS) and the US Army Ranger School. He holds an advanced degree in finance from Yale
                                    (MBA), where he interned at Goldman Sachs, and served as Teaching Assistant to Professor Frank
                                    J. Fabozzi.
                                    <br />
                                    Email : <a href="mailto:joliver@battlemonument.com "
                                        style={{ textDecoration: `underline` }}>joliver@battlemonument.com</a>
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div class="row ">
                            <div class="col-sm-5  portrait-container">
                                <img src={Mike} class="portrait img-fluid " alt="Mike Brumagin" />
                            </div>
                            <div class="col-sm-7 ">
                                <h6>Mike Brumagin</h6>
                                <p>Founding Partner and Fund Manager</p>
                                <p>Mike is a Co-Founder and General Partner at Battle Monument Group. He is also Co-owner at
                                    Integrity Restaurant Group. He has 25 years of experience as a principal owner of more than 100
                                    fine-dining, fast-casual, and QSR restaurants, along with their owner-occupied properties. He
                                    served with the 24th Infantry Division during Operation Desert Storm, where he received the
                                    Bronze Star, and is a graduate of West Point (BS). He holds an advanced degree in business from
                                    UC Irvine (MBA), where he received the Irvine Award as Valedictorian.
                                    <br />
                                    Email : <a href="mailto:mbrumagin@battlemonument.com "
                                        style={{ textDecoration: `underline` }}>mbrumagin@battlemonument.com</a>
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div class="row ">
                            <div class="col-sm-5  portrait-container">
                                <img src={Peter} class="portrait img-fluid "  alt="Peter Stark" />
                            </div>
                            <div class="col-sm-7 ">
                                <h6>Peter Stark</h6>
                                <p>Partner and Fund Manager</p>
                                <p>Peter is a Principal and General Partner at Battle Monument Group. He has more than 20 years of
                                    experience in equity and debt capital markets. He was previously a Managing Director with
                                    Raymond James Financial, with a focus on institutional equity trading, and securities markets.
                                    He received a Bachelor of Science (BS) from West Point and a degree in Finance from NYU Stern
                                    School of Business (MBA). A decorated veteran of Operation Desert Storm, Peter also is the
                                    Scoutmaster for Boy Scout Troop 53 in Tampa, FL.
                                    <br />
                                    Email : <a href="mailto:peterstark@battlemonument.com "
                                        style={{ textDecoration: `underline` }}>peterstark@battlemonument.com</a></p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

export default Who;
