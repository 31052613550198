import React from "react";
import {Outlet} from 'react-router-dom'


import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";

class App extends React.Component {
  render() {
    return (
      <div id="frame">

        <Header />
        <Outlet />
        <Footer />
      </div>
    );
  }
}

export default App;
