import React from "react";
import './Faq.css';




class
    Faq extends React.Component {


    
        
    render() {



        return (
            <div id="aboutMain">

                <div class="pageHeader">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h1>| FREQUENTLY ASKED QUESTIONS</h1>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="container">

                    <div class="row">

                        <div class="col-12" id="FaqDiv">
                            <button type="button" class="collapsible">
                                What makes the assets in our DST a good vehicle for your 1031 exchange?
                            </button>

                            <div class="collapsecontent">
                                <p>We have a steady supply of stabilized, credit-grade properties that are well-suited for 1031 Exchange investors. Our typical investment yield is above 5% over the life of the assets, with strong potential for capital gains on an approximately 5-year horizon. Our current DST has eight such properties.</p>
                            </div>

                            <button type="button" class="collapsible">
                                What is the targeted return?
                            </button>

                            <div class="collapsecontent">
                                <p>5.6% annual yield, paid in quarterly installments in year 1, increasing to targeted 6.0% annual yield over the course of 5-years.

                                </p>
                            </div>

                            <button type="button" class="collapsible">
                                What is the term of the DST?
                            </button>

                            <div class="collapsecontent">
                                <p>The expected life span of the DST is approximately five-years, at which time the DST will be wrapped-up and investors should receive their initial investment and a pro-rata share of any net gain/loss resulting from the DST wrap-up.  Funds will be transferred back to the QI the investor designates.
                                </p>
                            </div>

                            <button type="button" class="collapsible">
                                What is the minimum investment and what is the associated debt allocation?
                            </button>

                            <div class="collapsecontent">
                                <p>The minimum investment is $250,000 (3.86% beneficial interest) with an allocation of debt (for 1031 investors only, not cash investors) of $212,234.
                                </p>
                            </div>

                            <button type="button" class="collapsible">
                                What documents are completed to participate in the DST??
                            </button>

                            <div class="collapsecontent">
                                <p>Investors must be accredited and will be required to provide documentation confirming accreditation status.  From there, investors will receive a Private Placement Memorandum (Disclosure), along with an Investor questionnaire and a Purchase Agreement.  These will be provided in DocuSign format, with required fields pre-designated for completion by the investor(s).
                                </p>
                            </div>

                            <button type="button" class="collapsible">
                                How are taxes handled through a DST vs my own 1031 property?
                            </button>

                            <div class="collapsecontent">
                                <p>investors receive a financial summary at the end of the calendar year, showing the net Income/Loss for the year, in an informal document to be provided to their tax professional in preparation of Schedule E.  There is no K-1 or 1099 - just data regarding Income/Loss.  Each prospective Investor is urged and expected to consult with their tax advisor regarding the potential tax consequences of an investment in the BMG DST.
                                </p>
                            </div>

                        </div>

                    </div>
                </div>



            </div>


        )
    }
}

export default Faq;
